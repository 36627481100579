// React-specific & utility imports
import React, { lazy, Suspense,useEffect } from "react";
import { createRoot } from "react-dom/client";
import { PARAMETER_LANGUAGE } from "src/Utilities/constants";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/authContext";
import { INITIAL_CONFIG } from "./Utilities/constants";
import {getUserLanguageFromBrowser} from "src/Utilities/language";
import alloyAnalytics from '@telus/alloy-analytics';

//CSS import
import "./index.css";
// SDK imports
import { TokenStorage, Config } from "@forgerock/javascript-sdk";
//Defer loading
const K1LoginJourney = lazy(() => import("./Journeys/K1LoginJourney"));
const F1RegistrationJourney = lazy(() =>
  import("./Journeys/F1RegistrationJourney")
);
const Q2_1ChangePasswordJourney = lazy(() =>
  import("./Journeys/Q2_1ChangePasswordJourney")
);
const Q1_2ChangeEmailJourney = lazy(() =>
  import("./Journeys/Q1_2ChangeEmailJourney")
);
const N1N2ForgotPasswordJourney = lazy(() =>
  import("./Journeys/N1N2ForgotPasswordJourney")
);
const Q1ChangeNameJourney = lazy(() =>
  import("./Journeys/Q1ChangeNameJourney")
);
const Q1_3Q2ChangeAddPhoneJourney = lazy(() =>
  import("./Journeys/Q1_3Q2ChangeAddPhoneJourney")
);
const ActivatePhoneJourney = lazy(() =>
  import("./Journeys/ActivatePhoneJourney")
);
const O2CommunityForgotEmailJourney = lazy(() =>
  import("./Journeys/O2CommunityForgotEmailJourney")
);
const RecoverEmailLoginJourney = lazy(() =>
  import("./Journeys/RecoverEmailLoginJourney")
);
const RecoverEmailLoginJourneyCommunity = lazy(() =>
  import("./Journeys/RecoverEmailLoginJourneyCommunity")
);
const F1ActivationJourney = lazy(() =>
  import("./Journeys/F1ActivationJourney")
);
const K3CommunityLoginJourney = lazy(() =>
  import("./Journeys/K3CommunityLoginJourney")
);
const F2CommunityRegisterJourney = lazy(() =>
  import("./Journeys/F2CommunityRegisterJourney")
);
const O1ForgotEmailJourney = lazy(() =>
  import("./Journeys/O1ForgotEmailJourney")
);
const SelfServePage = lazy(() => import("./components/SelfServePage"));
const MyTrustedDevicesPage = lazy(() =>
  import("./components/MyTrustedDevices")
);
const AuthLandingPage = lazy(() => import("./Journeys/AuthLandingPage"));
const PrimSSOJourney = lazy(() => import("./Journeys/PrimSSOJourney"));
const LOA2Journey = lazy(() => import("./Journeys/LOA2Journey"));
const LOA2PMJourney = lazy(() => import("./Journeys/LOA2PMJourney"));
const LOAKhorosJourney = lazy(() => import("./Journeys/LOAKhorosJourney"));
const Error404LandingPage = lazy(() =>
  import("./Journeys/Error404LandingPage")
);

const LogoutPage = lazy(() => import("./Utilities/Logout"));

import Spinner from "./components/Spinner/Spinner";
import { BaseProvider } from "@telus-uds/components-base";
import alliumTheme from "@telus-uds/theme-allium";
import "@telus-uds/palette-allium/build/rn/fonts/fonts.css";
import publicMobileRebrandTheme from "@telus-uds/themes-community.theme-public-mobile-rebrand";
import "@telus-uds/palettes-community.palette-public-mobile-rebrand/build/rn/fonts/fonts.css";
import analyticsScripts from '@telus/analytics-scripts';

Config.set(INITIAL_CONFIG);
(async function initAndHydrate() {
  let isAuthenticated;
  try {
    isAuthenticated = !!(await TokenStorage.get());
  } catch (err) {
    // console.error(
    //   `There was an error retrieving the token for hydration: ${err}`
    // );
  }
  const container = document.getElementById("root");
  const root = createRoot(container);

  const themePM = publicMobileRebrandTheme;
  const themeAllium = alliumTheme;

  const userLanguage = getUserLanguageFromBrowser();
  const currURL = (window) ? window?.location?.href ?? '' : '';
  const envApp = currURL.includes('eversafe.id.telus') ? 'production' : 'staging';
  const TAG_MANAGER_PATH = analyticsScripts.getTagManagerScriptPath(envApp);

  //set HTML title dynamically
  if ( userLanguage ==="fr" ){
    document.title = 'Public Mobile';
  } else {
    document.title = 'Public Mobile';
  }
  let loadingTxt = userLanguage==="fr"?"Chargement...":"Loading...";

  function Init() {
    return (
      <Router>
        <AuthContextProvider>
          <BaseProvider defaultTheme={themePM}>
            <Routes>
              <Route
                path="/user/test/callback"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <h3>/login/callback</h3>
                  </Suspense>
                }
              />
              <Route
                path="/user/login"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <K1LoginJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/community-login"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <K3CommunityLoginJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/register"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <F1RegistrationJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/community-register"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <F2CommunityRegisterJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/health"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <h2>/health -- healthy</h2>
                  </Suspense>
                }
              />
              <Route
                path="/user/changepassword"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <Q2_1ChangePasswordJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/changephone"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <Q1_3Q2ChangeAddPhoneJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/mytrusteddevices"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <MyTrustedDevicesPage />
                  </Suspense>
                }
              />
              <Route
                path="/user/selfservemenu"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <SelfServePage />
                  </Suspense>
                }
              />
              <Route
                path="/user/community-forgotemail"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <O2CommunityForgotEmailJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/recoveremaillogin"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <RecoverEmailLoginJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/recoveremailcommunitylogin"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <RecoverEmailLoginJourneyCommunity />
                  </Suspense>
                }
              />
              <Route
                path="/user/changeemail"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <Q1_2ChangeEmailJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/changename"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <Q1ChangeNameJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/forgotpassword"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <N1N2ForgotPasswordJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/forgotemail"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <O1ForgotEmailJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/activate"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <F1ActivationJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/auth"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <AuthLandingPage />
                  </Suspense>
                }
              />
              <Route
                path="/user/primSSO"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <PrimSSOJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/loa2"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <LOA2Journey />
                  </Suspense>
                }
              />
              <Route
                path="/user/loa2pm"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <LOA2PMJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/khoros"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <LOAKhorosJourney />
                  </Suspense>
                }
              />
              <Route
                path="/user/logout"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <LogoutPage />
                  </Suspense>
                }
              />
              <Route
                path="/user/error"
                element={
                  <Suspense fallback={<Spinner message={loadingTxt} />}>
                    <Error404LandingPage />
                  </Suspense>
                }
              />
            </Routes>
          </BaseProvider>
        </AuthContextProvider>
      </Router>
    );
  }
  // Mounts the React app to the existing root element
  root.render(<Init />);
})();
